
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import Preloader from "@/components/ui/Preloader.vue";
import gtmAuthPlace from "@/mixins/gtmAuthPlace";

export default defineComponent ({
  name: "Testimonials",
  components: {
    Preloader
  },
  mixins: [gtmAuthPlace],
  computed: {
    ...mapGetters({testimonials: "testimonialsList/testimonialsList"})
  },
  methods: {
    ...mapActions('testimonialsList', ['getTestimonialsList']),
    onBottomButton() {
      this.gtmTrackAndSave('signup', 'Hero Banner');
      //@ts-ignore
      window.location.href = 'https://allstars.gifted.co/';
    }
  },
  created() {
    this.getTestimonialsList();
  }
})
